.input-wrap {
  margin: 1rem 0;
}

.input-wrap label,
.input-wrap input {
  display: block;
}

.input-wrap label {
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.input-wrap input {
  height: 2.5rem;
  width: 100%;
  /* padding: 0.5rem;
  color: var(--text-27);
  font-weight: 500;
  border-radius: 6px; */
}

.input-wrap input:focus {
  outline: none;
}


.reimburse-modal-form .field-row label {
  color: #3b3b3b;
  text-align: left;
  display: inline-block;
  margin-bottom: 5px;
}

.reimburse-modal-form .ant-select {
  width: 100%;
}

.reimburse-modal-form .ant-select .ant-select-selector,
.reimburse-modal-form .ant-input {
  border-radius: 6px !important;
  height: 3rem !important;
}

.reimburse-modal-form .ant-picker {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 100%;
  height: 3rem !important;
}

.reimburse-modal-form textarea {
  resize: none;
}

.reimburse-modal-form .field-row {
  margin-bottom: 1rem;
}
