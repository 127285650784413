.organisation {
    padding: 1rem;
}

.organisation .organisation_section {
    width: 100%;
    padding-top: 3rem;
    min-height: calc(100vh - 7.7rem);
}

.organisation .organisation_container {
    width: 80%;
    margin: 0 auto;
}

.organisation .admin_name {
    color: #262626;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
}

.organisation .description {
    color: #767676;
    font-size: 1rem;
    font-weight: 500;
}

.organisation .organisation_cards_section {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 2rem;
    border: 1px solid #D8D8D8;
    margin-top: 2rem;
}

.organisation .organisation_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #D8D8D8;
    border-radius: 12px;
    padding: 20px 20px 30px 20px;
    position: relative;
}

.organisation .organisation_logo {
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 10px;
}

.organisation .organisation_logo img {
    width: 65%;
    height: 75%;
    border-radius: 5px;
}

.organisation .organisation_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.organisation .organisation_name {
    color: #262626;
    font-size: 1.2rem;
    font-weight: 600;
}

.organisation .user_name {
    color: #262626;
    font-size: 1.1rem;
    font-weight: 600;
}

.organisation .organisation_create,
.organisation .organisation_id,
.organisation .organisation_admin {
    color: #767676;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
}

.organisation .role {
    color: #363636;
    font-weight: 600;
}

.organisation .button_setion {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.organisation .go_to_organisation {
    background: #FFFFFF;
    border: 1px solid #3F81F4;
    border-radius: 6px;
    color: #3F81F4;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 6px 10px;
}

.organisation .go_to_organisation.primarycolor {
    background: #3F81F4;
    border: 1px solid #3F81F4;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 6px 10px;
    min-width: 13rem;
}

.organisation .delete_organisation {
    background: #FFEDEE;
    border-radius: 6px;
    padding: 6px 10px;
    margin-top: 0.7rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #ED3B41;
}


.organisation .delete_organisation.no_drop {
    cursor: no-drop;
}

.organisation .badge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.organisation .badge img {
    width: 100%;
}

.portal-checkbox .ant-checkbox-wrapper {
    margin-right: 0.5rem;
}

.portal-checkbox {
    margin-bottom: 1rem;
}

.portal-field-label .main-text {
    margin-bottom: 0rem;
    font-size: 1.1rem;
}

.portal-field-label .sub-text {
    color: #000;
    opacity: 0.8;
    font-size: 0.9rem;
}