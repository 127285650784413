.sign-up span {
    color: #212121;
    opacity: 1;
    font-size: 0.8rem;
    font-weight: 600;
}

.signup-container .otp_info {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
}

.signup-container .otp_info span {
    color: #767676;
    opacity: 1;
    font-weight: 600;
    font-size: 0.8rem;
}

.signup-container .verify_button span {
    color: #3F81F4;
    opacity: 1;
    font-weight: 600;
}

.signup-container .resend-button button {
    padding: 0;
    display: flex;
}

.signup-container .resend-button button span {
    color: #3F81F4;
    opacity: 1;
    font-weight: 600;
}

.signup-container .sub-header span {
    color: #7C7D7E;
    font-size: 1rem;
    font-weight: 400;
}

.signup-container .prefix .ant-input-prefix {
    color: #7C7D7E;
    font-size: 1rem;
    font-weight: 500;
}

.signup-container .check-box-button {
    gap: 1rem;
    display: grid;
    grid-template-columns: 30rem 30rem;
}

.signup-container .check-box-button .checkbox-group {
    gap: 1rem;
    display: grid;
    grid-template-columns: 30rem 30rem;
}

.signup-container .check-box-button button {
    display: flex;
    align-items: center;
    width: 30rem;
    height: 6.5rem;
    background: #F5F7FA;
    border-radius: 7px;
    opacity: 1;
    padding-left: 1.5rem;
    padding-right: 2rem;
    transition: background 1s;
}

.signup-container .check-box-button .ant-checkbox-group label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 30rem;
    height: 6.5rem;
    background: #F5F7FA;
    border-radius: 7px;
    opacity: 1;
    padding-right: 2rem;
    transition: background 1s;
    gap: 1rem;
    margin-left: 0rem !important;
    border: 1px solid #cdd0d5 !important;
}

.signup-container .check-box-button .ant-checkbox-group .ant-checkbox-wrapper-checked {
    background: #3F81F4;
    box-shadow: 0px 0px 9px #01276A29;
    border: none;
    border-radius: 7px;
    opacity: 1;
}

.signup-container .check-box-button .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #FFFFFF !important;
}

.signup-container .check-box-button .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner:hover {
    border: 1px solid #d9d9d9 !important;
}

.signup-container .check-box-button .ant-checkbox-group .ant-checkbox-wrapper-checked span {
    color: #FFFFFF;
}

.signup-container .check-box-button .ant-checkbox-group .ant-checkbox-wrapper-checked .text-div {
    color: #FFFFFF;
    opacity: 1;
}

.signup-container .check-box-button .ant-checkbox-group label span:nth-child(2) {
    display: flex;
    align-items: center;
}

.signup-container .check-box-button .ant-checkbox-group label span svg {
    font-size: 1rem;
}

.signup-container .check-box-button .ant-btn {
    border: none;
    outline: none;
}

.signup-container .check-box-button .text-div {
    margin-left: 1rem;
    margin-right: 1.5rem;
    white-space: pre-wrap;
    text-align: left;
    transition: color 0.5s;
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.8;
}

.signup-container .check-box-button span {
    color: #212121;
    transition: color 0.5s;
}

.signup-container .check-box-button span:nth-child(2) {
    color: #212121;
    font-size: 0.9rem;
    font-weight: 400;
}

.signup-container .check-box-button .button-clicked {
    background: #3F81F4;
    box-shadow: 0px 0px 9px #01276A29;
    border: none;
    border-radius: 7px;
    opacity: 1;

}

.signup-container .check-box-button .button-clicked span {
    color: #FFFFFF;
    opacity: 1;
}

.signup-container .check-box-button .button-clicked .text-div {
    color: #FFFFFF;
    opacity: 1;
}

.signup-container .check-box-button button .ant-checkbox-checked .ant-checkbox-inner {
    border: 0.75px solid #FFFFFF !important;
}

.signup-container .check-box-button button .ant-checkbox-checked .ant-checkbox-inner:hover {
    border: 0.75px solid #FFFFFF !important;
}

.signup-container .margin-auto {
    margin: 2rem auto;
}



/* kyc screens css start */
.kyc-section {
    width: 100%;
    min-height: 100vh;
    background-color: #FFFFFF;
}

.kyc-section .kyc-container {
    width: 50%;
    margin: auto;
}

.kyc-section .back-button {
    margin: 1rem 0rem;
}

.kyc-section .back-button span {
    color: var(--primary-color);
}

.kyc-section .back-button button {
    font-size: 0.9rem;
    padding: 0rem;
    font-weight: 500;
}

.kyc-section .kyc-header-text {
    color: #212121;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0rem;
    opacity: 0.9;
}

.kyc-section .kyc-sub-header-text {
    color: #7C7D7E;
    font-size: 0.9rem;
    font-weight: 600;
    opacity: 0.7;
}

.kyc-section .kyc-header {
    margin-bottom: 2.5rem;
}

.kyc-section .kyc-card {
    background: #F5F7FA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 7px;
    opacity: 1;
    padding: 2rem 3rem 3rem 3rem;
    margin-bottom: 2.5rem;
}

.kyc-section .kyc-card .card-header {
    margin-bottom: 1.5rem;
}

.kyc-section .kyc-card .card-header-text {
    color: #212121;
    font-size: 1rem;
    font-weight: 600;
    opacity: 0.8;
    margin-bottom: 1rem;
}

.kyc-section .kyc-card .card-sub-header-text {
    color: #7C7D7E;
    font-size: 0.9rem;
    font-weight: 600;
    opacity: 0.7;
}

.kyc-section .button-section button:nth-child(2) span {
    color: var(--primary-color);
}

.kyc-section .button-section button span {
    font-size: 1rem;
    font-weight: 600;
}

.kyc-section .select-section .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F5F7FA !important;
    border: none;
    outline: none;
    border-bottom: 1px solid #d9d9d9;

}

.kyc-section .select-section .ant-select-selector {
    height: 3.1rem !important;
}

.kyc-section .select-section .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}