.shimmer td > span {
  position: relative;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #f2f4f7 10%,
    #f0f0f2 20%,
    #f2f4f7 30%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 200px;

  /* Animation */
  animation-duration: 1s;

  /* Specifies style for element when animation isn't playing */
  animation-fill-mode: forwards;

  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: ease-in-out;
  height: 30px;
  display: block;
}

.shimmer > div.loader {
  position: relative;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #f2f4f7 10%,
    #f0f0f2 20%,
    #f2f4f7 30%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 480px;

  /* Animation */
  animation-duration: 1s;

  /* Specifies style for element when animation isn't playing */
  animation-fill-mode: forwards;

  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: ease-in-out;
  height: 480px;
  width: 95%;
  margin: auto;
  display: block;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.bg-white {
  padding: 2rem;
  background-color: white;
}
