.count_error .ant-input {
    border-color: red;
}

.count_error .ant-input:focus {
    border-color: red;
    box-shadow: 0 0 6px 0 rgba(255, 0, 0, 0.5);
}

.count_error .ant-input:hover {
    border-color: red;
}

.count_error .ant-input-affix-wrapper {
    border-color: red !important;
}

.count_error .ant-input-affix-wrapper-focused {
    border-color: red !important;
    box-shadow: 0 0 6px 0 rgba(255, 0, 0, 0.5) !important;
}

.count_error .ant-input-affix-wrapper:hover {
    border-color: red !important;
}