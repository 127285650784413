.report-info .title-card:first-child {
  flex: 0.6;
}
.report-info .title-card:last-child {
  flex: 0.4;
}

.report-info {
  display: flex;
  margin: 2rem;
  gap: 2rem;
}

.report-info .title-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.report-info .title-card p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.report-info .sm {
  font-size: 0.9rem;
}

.report-info .title-card p {
  flex: 0.33;
  margin: 1rem;
  border-right: 1px solid #edf0f5;
}

.report-info .title-card p:last-child {
  border-right: none;
}

.report-total {
  padding: 1rem 2rem;
}

.report-total p label {
  color: #58666e;
  font-weight: 600;
  font-size: 0.8rem;
}

.report-total p span.lg {
  font-size: 1.3rem;
}

.report-total p span.link {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.8rem;
}

.report-total.title-card p {
  flex: 0.5;
}

.expense-info .title-card {
  padding: 15px;
}

.expense-info {
  grid-gap: 2rem;
  gap: 2rem;
}

.expense-info h3,
.expense-info span,
.expense-info p {
  color: #58666e;
}

.expense-info .title-card button span {
  color: #fff;
}

.expense-info h2 {
  color: rgba(0, 0, 0, 0.85) !important;
}

.expense-info .boldHead p {
  color: #333;
  font-weight: 500;
}

.contentAreas .ant-collapse {
  background-color: #fff;
  border: none;
  margin-top: 20px;
}

.contentAreas .ant-collapse .ant-steps-item-tail::after {
  background-color: #1890ff;
  margin-top: 1px;
}

.contentAreas .ant-collapse .ant-collapse-item {
  border-bottom: none;
}

.business-info {
  margin: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.business-info .title-card p {
  margin: 1rem 3rem;
  border-right: 1px solid #edf0f5;
  display: inline-block;
  padding-right: 3rem;
}

.business-info .title-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.business-info .title-card p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.report-detail .ant-collapse-borderless {
  background-color: #fff !important;
}

.report-detail .ant-collapse-header span {
  color: #4b5660;
  font-weight: 600;
  display: block !important;
  flex: 0.5;
}

.report-detail .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid var(--text-db);
}

.report-detail .ant-collapse-header span:first-child {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
}

.report-detail .ant-collapse-header span strong {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 50%;
  font-weight: 600;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.report-detail .ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.totalsSection {
  margin-right: 35px;
}

.totalsSection p {
  color: var(--text-primary);
  float: right;
  width: 354px;
}

.totalsSection span {
  color: var(--text-primary);
  float: right;
}

.totalBar {
  background: var(--text-f5);
  text-align: right;
  padding: 5px;
}

.totalBar p,
.totalBar span {
  color: var(--text-primary);
  margin-left: 70px;
}

.totalBar p {
  margin: 10px 30px;
}

.addComment input {
  width: 500px;
  border-radius: 6px;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  padding: 23px 0 0px;
}

.popOvers {
  box-shadow: none !important;
  padding: 0px !important;
  border: none !important;
}

.ant-popover-inner {
  width: 100%;
}

.addExpComment input {
  width: 220px;
  border-radius: 6px;
}

.commentBody {
  color: var(--text-52);
  margin-bottom: 0px;
}

.commentData {
  color: var(--text-a4);
  font-size: 12px;
}

.commentsContent hr:last-child {
  display: none;
}

.commentsSection .ant-steps-item-tail::after {
  background-color: var(--primary-color) !important;
  margin-top: 1px !important;
}

.policyVoiButton {
  margin: 18px 5px !important;
  border-radius: 6px !important;
}

.policyVoiButton span {
  color: #ff4d4f;
}

.imgWrapper span {
  color: #fff;
  background: var(--color);
  width: 17px;
  height: 17px;
  border-radius: 20px;
  position: absolute;
  font-size: 10px;
  right: -8px;
  top: -8px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgWrapper {
  position: relative;
  margin-right: 0.6rem;
  background-color: var(--bg-color);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reasonTextArea {
  margin-top: 1rem !important;
  resize: none;
}

.pop-card {
  display: flex;
  flex-wrap: wrap;
  width: 30rem;
  gap: 0.7rem;
}

.pop-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.pop-card p {
  display: inline-block;
  margin-right: 20px;
}

.pop-card p span.dim {
  opacity: 0.4;
}

.summaryCard {
  width: 92px;
}

.content2Wrapper {
  background: var(--text-white);
  border-radius: 5px;
  padding: 2rem 1rem 3rem 1rem;
}

.exp-title {
  display: flex;
  flex-direction: column;
}

.floatRightButtons {
  display: flow-root;
}

.floatRightButtons button {
  float: right;
}

.tableStyled .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(0.6) translate(-220%, -80%);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}

.summary {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
  padding: 1rem;
}

.summary label {
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
}

.summary span {
  color: #3b3b3b;
}

.summary.total {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.summary .flex-between {
  margin-bottom: 5px;
}

.primary-text {
  color: var(--primary-color);
  font-weight: 600;
}
.reports-div .ant-checkbox-checked::after {
  border: none;
}
.fs-1 {
  font-size: 1rem;
}

.negative label {
  color: #4b5660;
  font-weight: 600;
}

.check-text {
  color: #3b3b3b;
}
.negative table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.negative table thead tr th {
  background: #f5f7fa 0% 0% no-repeat padding-box !important;
  padding-left: 10px !important;
}

.negative table thead tr th {
  padding: 0.7rem 0;
}

.negative .ant-table-thead > tr > th::before {
  background: none !important;
}

.commentsSection .ant-steps-item-finish .ant-steps-item-icon,
.commentsSection .ant-steps-item-active .ant-steps-item-icon {
  background: #f7f7f7 0% 0% no-repeat padding-box !important;
  padding: 8px;
  border-radius: 50% !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentsSection .ant-steps-icon {
  line-height: 0 !important;
  left: 0 !important;
  height: fit-content !important;
}

.commentsSection .ant-steps-item-tail {
  top: 4px !important;
  left: 18px !important;
}

.commentsSection .ant-steps-item-tail::after {
  background-color: #f7f7f7 !important;
}

.report-error {
  /* background-color: #fff5f4; */
}

.report-error.forReport {
  margin: 1rem;
  border-radius: 4px;
}

.report-error p {
  color: #e35453;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.report-error p.warn .base_currency {
  color: orange;
}

.report-error p.warn {
  color: orange;
}

.report-error p .base_currency {
  color: #e35453;
}

.expense .anticon-close {
  position: relative;
  top: -10px;
  right: -10px;
}

.add-report {
  border-top: 1px solid #eaeaea;
  width: 100%;
  padding: 10px;
  text-align: left;
  color: #333;
}

.rtl .addComment input {
  width: 410px;
}