.bottom_margin {
    margin-bottom: 1.1rem;
}

.email_header{
    margin-top: 2.6rem;
}
.email_header .container{
    padding-left: 4.3rem;
}
.email_header .container div{
    display: flex;
}
.email_header .container .label{
    flex: 0.1;
    color: #000000;
    font-weight: normal;
    font-size: 1rem;
}
.email_header .container .form_div{
    flex: 0.9;
}
.email_header .container .form_div div{
    flex: 0.9;
}
.email_header .container .form_div input{
    border: 1px solid #d9d9d9;
}

.email_section .email_from {
    width: 39rem;
    margin: 2rem auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px #0000001A;
    border-radius: 6px;
    padding: 2.5rem 3.4rem 1rem 3.75rem;
}

.email_section .email_header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email_section .circle {
    width: 4.1rem;
    height: 4.1rem;
    border-radius: 50%;
    background: #EAEAEA;
    color: #4B5660;
    opacity: 0.41;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;

}

.email_section .header {
    font-size: 1.2rem;
    color: #4B5660;
    opacity: 1;
    font-weight: bold;
}

.email_section .header.sm {
    font-size: 1.2rem;
    margin-bottom: 0px;
}

.email_section .header.lg {
    font-size: 1.8rem;
}

.email_section .header.label {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
    color: #565555;
    font-weight: normal;
}

.email_section .header.light {
    font-size: 0.9rem;
    font-weight: normal;
    color: #333333;
    opacity: 0.66;
    margin-bottom: 1.5rem;
}

.email_section .line {
    width: 100%;
    height: 0.4px;
    background: #95989A;
    opacity: 0.29;
}

.email_section .email_body div {
    display: flex;
    justify-content: space-between;
}

.email_section .body {
    font-size: 1rem;
    color: #262626;
    font-weight: 600;
    opacity: 1;
}

.email_section .body.color {
    color: #333333;
    font-weight: normal;
}

.email_section .note {
    color: #313131;
    opacity: 0.23;
    font-size: 1rem;
    margin-bottom: 0.6rem;
}

.email_section .body_button {
    margin: 2.5rem auto 1.5rem;
    background: #3F81F4;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    padding: 0.6rem 1.5rem;
    color: #FFFFFF;
    font-weight: 600;
}
.email_section .footer_header{
    color: #262626;
    font-size: 0.9rem;
    font-weight: 600;
}
.email_section .footer_cards{
    margin: 0.6rem 0rem 0.9rem;
    background: #F2F2F2;
    border-radius: 5px;
    padding: 10px 18px;
}
.email_section .footer_cards{
    display: flex;
    justify-content: space-evenly;
}
.email_section .footer_cards div{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.email_section .footer_cards img{
    width: 1.5rem;
}
.email_section .footer_cards span{
    font-size: 0.9rem;
    font-weight: 600;
    color: #313131;
}

.email_section .footer_text{
    font-size: 0.8rem;
    color: #313131;
    font-weight: normal;
}


.email_section .overlay{
    width: 100%;
    height: 4.6rem;
    /* position: absolute; */
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    /* top: 106%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.7rem;
    padding-right: 5rem;
}
.email_section .overlay button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1.1rem;
    border-radius: 5px;
    border: 1px solid #3f81f4;

}
.email_section .overlay button{
    font-weight: 600;
    font-size: 1rem;
}
.email_section .overlay .primary{
    color: #FFFFFF;
    background: #3f81f4;
}
.email_section .overlay .border span{
    color: "#3f81f4" !important;
}

.new-bill-form .check_box {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
}
.new-bill-form .check_box span{
    font-size: 1rem;
    color: #2E2E2E;
    font-weight: normal;
}


.email_header .multi_input{
    flex: 0.81 !important;
}
.email_header .multi_input .ant-select-selector{
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}