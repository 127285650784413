.type-link-button{
    color: #3f81f4 !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
}

.save-button{
  width: auto !important;
  height: 3.5rem !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-top: 0 !important;
  border-radius: 5px !important;
  background-color: #3f81f4 !important;
  color: #fff !important;
}
.cancel-button{
  width: auto !important;
  height: 3.5rem !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-top: 0 !important;
  border-radius: 5px !important;
  border-color: #3f81f4 !important;
}
.trips-detail .tabs-sec{
  box-shadow: 0px 3px 12px #3b3b3b0a !important;
    border: 1px solid #EDF0F5 !important;
    background: #fff !important;
}
.trips-info .title-card:first-child {
  flex: 0.6;
}
.trips-info .title-card:last-child {
  flex: 0.4;
}

.trips-info {
  display: flex;
  margin: 2rem;
  gap: 2rem;
}

.title-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.title-card p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.trips-info .sm {
  font-size: 0.9rem;
}

.title-card p {
  flex: 0.33;
  margin: 1rem;
  border-right: 1px solid #edf0f5;
}

.title-card.loop p {
  flex: none;
}

.title-card.loop {
  gap: 0;
  grid-gap: 0;
  flex-wrap: wrap;
}

.title-card p:last-child {
  border-right: none;
}

.trips-total {
  padding: 1rem 2rem;
}

.trips-total p label {
  color: #58666e;
  font-weight: 600;
  font-size: 0.8rem;
}

.trips-total p span.lg {
  font-size: 1.3rem;
}

.trips-total {
  display: flex;
  flex-direction: column;
}

.trips-total span {
  color: #58666e;
}

.trips-total p span.link {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.8rem;
}

.trips-total.title-card p {
  flex: 0.5;
}

.trips-detail .ant-collapse-borderless {
  background-color: #fff !important;
}

.trips-detail .ant-collapse-header span {
  color: #4b5660;
  font-weight: 600;
  display: block !important;
  flex: 0.5;
}

.trips-detail .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid var(--text-db);
}

.trips-detail .ant-collapse-header span:first-child {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
}

.trips-detail .ant-collapse-header span strong {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 50%;
  font-weight: 600;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.trips-detail .ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.trips-detail .ant-steps-item-icon {
  width: 40px;
  height: 40px !important;
  background: #e4e4e4 !important;
}

.trips-detail .ant-steps-item-icon .anticon {
  float: none;
  margin-top: 8px;
  color: #333;
}

.trips-detail .ant-steps-item-container .ant-steps-item-title {
  width: 100%;
}

.travel_type .ant-radio-wrapper span {
  color: var(--text-primary) !important;
}

.addTripHead {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #3b3b3b0a;
  height: 45px;
  padding: 10px 20px;
  color: var(--primary-color);
  font-weight: 600;
  border-radius: 11px;
}

.headLabels {
  font-weight: 600;
  color: #3b3b3b !important;
}

.addNewInput {
  border: 1px solid #d9d9d9;
  border-radius: 7px !important;
  width: 30em !important;
}

.tripPurpose {
  resize: none;
  border-radius: 7px !important;
  width: 30em !important;
}

.trip-tables h3 {
  color: #333;
}

.tripsTable .ant-empty-normal {
  margin: 0px 0;
}
.headLabels.trip-header{
  font-size: 1.4rem;
  font-weight: 600;
  color: #4B5660 !important;
text-transform: capitalize;
opacity: 1;
}
.trips-steps-div .ant-steps-item-title{
  width: 100% !important;
  font-size: 1.2rem;
  font-weight: 600;
  color: #4B5660 !important;
text-transform: capitalize;
opacity: 1;
}
.trips-steps-div
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.trips-steps-div
.ant-table-container
table
> thead
> tr:last-child
th:last-child {
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
}


.trips-steps-div .ant-table-thead > tr > th {
  background: #e4e9f2;
  color: #767676;
  font-weight: 600;
  font-size: 1.15rem;
}


.trips-steps-div button{
  width: auto;
  height: 3.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  border-radius: 5px;
}

/* .trips-steps-div .ant-steps-item-tail{
  border-left: 2px solid #f0f0f0;
} */
.trips-steps-div .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0 !important;
}
.trips-steps-div .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {

  left: 20px !important;
}
.trips-button-div .primaryButton{
  width: auto;
  height: 3.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  border-radius: 5px;
}
.trips-button-div .cancelButton{
  width: auto;
  height: 3.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  border-radius: 5px;
  border-color: #3f81f4;
}

/* trips form css start */
.trips-form-div .ant-form-item-label label{
  font-size: 1.2rem !important;
  font-weight: normal !important;
  color: #3B3B3B !important;
  opacity: 0.9 !important;
}



/* modal css */
.trips-modal .ant-modal-header .ant-modal-titel{
  width: 100% !important;
  font-size: 1.2rem !important;
  font-weight: 600;
  color: #4B5660 !important;
  text-transform: capitalize !important;
  opacity: 1;
}