.field_length.kyc {
  width: 35%;
}

.business-main-section {
  width: 100%;
  background-color: #ffffff;
}

.form-button .button-div,
.text-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-div {
  gap: 0.6rem;
}

.form-button .text-div span {
  color: var(--text-7C);
}

.form-button .text-div img {
  width: 1.1rem;
  margin-right: 0.5rem;
}

.business-registration-section .check-box-group label {
  margin-right: 3rem;
}

.business-registration-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.business-details-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.business-address-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.form-upload-section {
  gap: 2rem;
}

.kyc-safe-icon {
  width: 1rem !important;
}

.business-address-section .form-upload-section .upload-button {
  border-color: #cbcbcb !important;
  border-radius: 6px;
}

/* upload Documents start  */
.upload-documents-section .upload-documents-container .ant-upload.ant-upload-drag {
  background-color: #ffffff;
  border-radius: 5px;
}

.upload-documents-section .upload-icon {
  height: 6.5rem;
  margin: 2.8rem auto 1.5rem;
}

.upload-documents-section .upload-icon img {
  height: 100%;
}

.upload-documents-section .upload-text {
  font-size: 1.1rem !important;
  color: #212121;
  font-weight: 600;
}

.upload-documents-section .upload-text img {
  width: 0.9rem;
  margin-right: 0.5rem;
}

.upload-documents-section .upload-hint {
  font-size: 1.1rem;
  font-weight: 500;
  color: #7c7d7e;
  margin: 1.2rem auto;
}

.upload-documents-section .upload-button button {
  border-color: #cbcbcb !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.upload-documents-section .upload-button button span {
  color: var(--primary-color);
}

.business-registration-container .pp-form-item.businessFields {
  width: 35%;
  display: inline-grid;
  margin-right: 4rem;
}

.business-registration-container .pp-form-item.businessFields.squareUpload {
  position: relative;
  width: 40%;
}



.business_image_section .business_image_container {
  border: 1px solid #CECECE;
  border-radius: 8px;
  width: 55rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
}

.business_image_section .business_image_container .image_section {
  width: 3rem;
  height: 4rem;
}

.business_image_section .business_image_container .file_name {
  color: #212121;
  font-size: 1rem;
  font-weight: 500;
}

.business_image_section .business_image_container .file_verify {
  color: #2FCC46;
  background: #E5FFE9;
  border-radius: 4px;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.business_image_section .business_image_container .preview_section,
.business_image_section .business_image_container .delete_section {
  cursor: pointer;
  width: 1.5rem;
  height: 1rem;
}

.preview_section_modal .ant-modal-content .ant-modal-body {
  display: flex;
  justify-content: center;
}

.business-registration-container .pp-form-item.businessFields.squareUpload .action_buttons {
  margin-top: 0.6rem;
}

.business-registration-container .pp-form-item.businessFields.squareUpload .hover_section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: none;
}

.business-registration-container .pp-form-item.businessFields.squareUpload:hover .hover_section {
  display: block;
}

.success_section {
  padding-top: 10rem;
}

.success_section .image_section {
  width: 13rem;
  height: 13rem;
}

.success_section .image_section img {
  width: 100%;
  height: 100%;
}

.success_section .application {
  color: #2C2C2C;
  font-size: 1.5rem;
}

.success_section .application_note span {
  color: #898989;
  font-size: 1.2rem;
  font-weight: 400;
}

.business-main-section .add-more-button {
  color: #3F81F4;
  font-size: 1.2rem;
  font-weight: 400;
}

.business-registration-section .header_section .remove_button {
  color: red;
}

.preview_section .preview_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #DBF1E6 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0.5rem 1rem;
}

.preview_section .info_image {
  width: 1rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.preview_section .info_text {
  color: #212121;
  font-size: 1rem;
  font-weight: 600;
}

.preview_section .preview_card {
  background: #FFFFFF;
  border-radius: 7px;
  /* padding: 0.5rem 1rem; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.preview_section .header_section {
  padding: 1.5rem 1rem;
}

.preview_section .header_section h4 {
  color: #212121;
  font-size: 1.2rem;
  font-weight: 600;
}

.preview_section .header_line {
  border: 1px solid #CECECE;
}

.preview_section .preview_data {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; */
  /* grid-auto-rows: minmax(50%, auto); */
  padding: 1.5rem 1rem;
}

.preview_section .preview_data .title {
  color: #7C7D7E;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.preview_section .preview_data .value {
  color: #212121;
  font-size: 1.1rem;
  font-weight: 500;
}

.preview_section .preview_edit {
  cursor: pointer;
}

.preview_data .key_value_div {
  display: inline-block;
  width: 48%;
}

.preview_data .preview_section {
  width: 1.5rem;
}

.prevew_document_section .image_section {
  width: 4rem;
  height: 3rem;
}