.form-sub-title span {
    color: #363636;
    font-size: 1.3rem;
    font-weight: 400;
}

.tax-sub-title span {
    color: #363636;
    font-size: 1.3rem;
    font-weight: 600;
}

.track-inventory .track-sub-header {
    color: #958f8f;
    margin-bottom: 0rem;
}

.track-inventory .track-header {
    margin-bottom: 0rem;
}