.header-title .card-title {
    color: rgba(18, 37, 73, .48);
    font-size: 1.2rem;
    font-weight: 600;
    padding-right: 10px;
}

.header-title .help-icon {
    color: #d3d9e1;
    cursor: pointer;
    font-size: 18px;
}

.header-title .link {
    color: #3F81F4;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
}

.customer-portal-section .card-container {
    background: #FFFFFF;
    box-shadow: 0px 3px 12px #E3E4E5;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    padding: 1.2rem 1.5rem 1.5rem;
}

.customer-portal-section .card-section .card-container-child {
    min-width: 33%;
}

.customer-portal-section .card-section .title {
    font-weight: 500;
    font-size: 1.1rem;
    color: #05152C;
    opacity: 0.6;
}

.customer-portal-section .card-section .title.total-count {
    font-size: 1rem;
    opacity: 0.4;
    margin-right: 0.4rem;
}

.customer-portal-section .card-section .card_circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #ccc;
    margin-right: 0.5rem;
}



.customer-portal-section .card-section .amount-total {
    color: #122549;
    font-weight: 600;
    font-size: 1.7rem;
}

.customer-portal-section .card-section .amount-total.amount {
    font-size: 1.4rem;
}

.customer-portal-section .card-section .paid-for {
    color: #697380;
    font-size: 1.1rem;
    font-weight: 400;
}

.customer-portal-section .card-section .paid-date {
    color: #122549;
    font-size: 1.1rem;
}

.customer-portal-section .card-section .customer-full-name {
    color: #122549;
    font-size: 1.2rem;
    font-weight: 500;
}

.customer-portal-section .card-section .customer-name {
    color: #697380;
    font-size: 1.1rem;
    font-weight: 400;
}

.customer-portal-section .contact-details {
    height: 19rem;
    overflow-y: auto;
}

.customer-portal-section .contact-details .contact-address {
    font-size: 1rem;
    color: #11264A;
    font-weight: 400;
}

.customer-portal-section .contact-details .contact {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    opacity: 0.5;
}

.contact-more-btn {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.customer-portal-section .contact-left {
    position: relative;
    margin-right: 1rem;
}

.customer-portal-section .contact-left::after {
    content: '';
    position: absolute;
    top: 12px;
    bottom: 0;
    right: 25px;
    width: 1px;
    height: 80%;
    /* Adjust the width of the line as needed */
    background-color: #aaaaaa;
    /* Adjust the color of the line as needed */
}

.customer-portal-section .send-email {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.billing-section .total-amount .all-amount {
    background: #F5F7FA 0% 0% no-repeat padding-box;
    border: 1px solid #EDF0F5;
    border-radius: 6px;
    display: flex;
    justify-content: end
}

.billing-section .total-amount .amount-div {
    display: flex;
    justify-content: end;
    flex-direction: column;
}

.billing-section .total-amount .title,
.billing-section .total-amount .amount {
    color: #222222;
    font-weight: 500;
    font-size: 1.2rem;
}

.profile-section.customer-profile .avatar-uploader .ant-upload {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.customer-invoice-action .paynow-button {
    background: #35aa09;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-radius: 5px;

}

.customer-invoice-action button:disabled {
    cursor: not-allowed;
}