.radio-header-text{
    color: #FF0000;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 1rem;
}
.radio-group label span{
    color: #333333 !important;
    font-size: 1rem;
    font-weight: 500;
}

.note .note-text{
    color: #969696;
    font-size: 0.9rem;
    font-weight: normal;
}
.note .note-text span{
    color: #363636;
    font-size: 0.9rem;
    font-weight: 500;
}
.note .note-text .back-link{
    color: #3F81F4;
    font-size: 0.8rem;
    display: block;
    padding: 0px;
}

/* Bank Balance css start  */
.balance-section{
    background: #E4E9F2;
    border: 1px solid #EAEAEA;
    border-radius: 7px;
    padding: 1rem 1.2rem;
}
.balance-section .balance-icon{
    width: 2.5rem;
    height: 2.5rem;
}
.balance-section .text-span{
    color: #05152C;
    opacity: 0.5;
    font-size: 0.9rem;
    font-weight: normal;
}
.balance-section .amount-span{
    color: #122549;
    font-size: 1.1rem;
    font-weight: 600;
}
.balance-section .vertical-line{
    border-right: 2px solid #D6D6D6;
    height: 2.2rem;
    margin: 0rem 1rem;
}


/* bank show css start  */
.bank-show-section .bank-show-text{
    font-size: 0.9rem;
    font-weight: 500;
    color: #767676;
}
.bank-show-section .bank-name img{
    width: 1.5rem;
}
.bank-show-section .bank-name span{
    font-size: 1rem;
    font-weight: 500;
    color: #363636;
    margin-left: 0.5rem;
}
.bank-show-section .right-side p{
    font-size: 0.95rem;
    font-weight: 500;
    color: #191919;
    margin-right: 0.8rem;
}
.bank-show-section .right-side p span{
    font-size: 0.9rem;
    color: #767676;
    margin-right: 0.2rem;
}
.bank-show-section .right-side button{
    font-size: 0.9rem;
    color: #3F81F4;
    font-weight: 500;
    padding: 3px 5px;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    opacity: 0.91;
}
.bank-show-section .right-side button img{
    width: 0.8rem;
}