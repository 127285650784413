.conditions-section .condition-header {
  margin: 0px -24px;
}
.conditions-section .condition-header span {
  font-size: 0.9rem;
  color: #333333;
  font-weight: 500;
  background: #e4e4e4;
  border: 1px solid #eaeaea;
  display: block;
  padding: 0.9rem;
  padding-left: 1.5rem;
}

.conditions-section .condition {
  margin: 2rem 0rem;
}

.conditions-section
  .condition
  .ant-collapse-borderless
  > .ant-collapse-item:last-child,
.conditions-section
  .condition
  .ant-collapse-borderless
  > .ant-collapse-item:last-child
  .ant-collapse-header {
  border-radius: 0;
  padding: 0px;
  background: #ffffff;
  padding-bottom: 0.8rem;
}
.conditions-section
  .approval
  .ant-collapse-borderless
  > .ant-collapse-item:last-child,
.conditions-section
  .approval
  .ant-collapse-borderless
  > .ant-collapse-item:last-child
  .ant-collapse-header {
  border-radius: 0;
  padding: 0px;
  background: #ffffff;
  padding-bottom: 0.8rem;
}
.conditions-section .condition .ant-collapse-header-text {
  font-size: 1.05rem !important;
  color: #4b5660 !important;
  font-weight: 600 !important;
}
.conditions-section .approval .ant-collapse-header-text {
  font-size: 1.05rem !important;
  color: #4b5660 !important;
  font-weight: 600 !important;
}
.conditions-section .condition .ant-collapse-header {
  border-bottom: 1px solid rgba(149, 152, 154, 0.43);
}
.conditions-section .approval .ant-collapse-header {
  border-bottom: 1px solid rgba(149, 152, 154, 0.43);
}
.conditions-section .condition .ant-collapse-content .ant-collapse-content-box {
  padding: 0rem;
  padding-top: 0.5rem;
}
.conditions-section .approval .ant-collapse-content .ant-collapse-content-box {
  padding: 0rem;
  padding-top: 0.5rem;
}
.conditions-section .condition .condition-note {
  color: #58666e;
  font-size: 0.9rem;
  font-weight: normal;
}
.conditions-section .approval .condition-note {
  color: #58666e;
  font-size: 0.9rem;
  font-weight: normal;
}
.conditions-section .condition .form-sectiom .ant-select {
  max-width: 15rem;
}
.conditions-section .condition .form-sectiom {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border-radius: 6px;
  padding: 1rem 0.8rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.conditions-section .approval .form-sectiom {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border-radius: 6px;
  padding: 1rem 0.8rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.conditions-section .condition .form-sectiom .ant-form-item {
  margin-bottom: 0rem;
}
.conditions-section .approval .form-sectiom .ant-form-item {
  margin-bottom: 0rem;
}
.conditions-section .condition .form-sectiom .ant-select-selector {
  border-radius: 5px !important;
}
.conditions-section .approval .form-sectiom .ant-select-selector {
  border-radius: 5px !important;
}
.conditions-section .condition .form-sectiom .one {
  /* width: 17%; */
}
.conditions-section .condition .form-sectiom .two {
  width: 17%;
}
.conditions-section .condition .form-sectiom .three {
  width: 28%;
}
.conditions-section .condition .form-sectiom .four {
  width: 20%;
}
.conditions-section .condition .form-sectiom .five {
  width: 28%;
}
.conditions-section .condition .form-sectiom .five input {
  min-height: 40px !important;
  border-radius: 5px !important;
}
.conditions-section .condition .form-sectiom .six {
  width: 1rem;
  height: 1rem;
  background: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding-top: 1px;
  cursor: pointer;
}
.conditions-section .approval .form-sectiom .two {
  width: 40%;
}
.conditions-section .approval .form-sectiom .three {
  width: 50%;
}
.conditions-section .approval .form-sectiom .four {
  width: 1rem;
  height: 1rem;
  background: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding-top: 2px;
  cursor: pointer;
}
.conditions-section .condition .add-one-more {
  margin-top: 1rem;
  display: inline-block;
}
.conditions-section .approval .add-one-more {
  margin-top: 1rem;
}
.conditions-section .condition .add-one-more button {
  color: #ed3b41;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: normal;
}
.conditions-section .approval .add-one-more button {
  color: #ed3b41;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: normal;
}
.conditions-section .condition .add-one-more button span {
  color: #ed3b41;
}
.conditions-section .approval .add-one-more button span {
  color: #ed3b41;
}
.conditions-section .approval .check-box {
  margin: 1rem 0rem;
}
.conditions-section .approval .check-box label span:nth-child(2) {
  color: #3b3b3b;
  font-size: 0.9rem;
  font-weight: normal;
}
.form-section .form-top .one label {
  font-size: 0.9rem;
  color: #3b3b3b;
  font-weight: normal;
  height: 0px;
}
.form-section .form-top .one .ant-form-item {
  margin-bottom: 10px;
}
.form-section .form-top .two label {
  font-size: 0.9rem;
  color: #3b3b3b;
  font-weight: normal;
  height: 0px;
}
