.navigation-section {
    margin-right: 8rem;
}

.navigation-section .navigation-conatiner {
    background: #F5F7FA;
    border-radius: 10px;
    width: 21rem;
    min-height: 100%;
}

.navigation-section {
    display: flex;
    justify-content: center;
}

.navigation-section .navigation-conatiner {
    min-width: 21rem;
    max-width: fit-content;
    height: 100%;
    background: #F5F7FA;
    border-radius: 10px;
    opacity: 1;
    padding: 4.3rem 0rem 0rem 3rem;
    text-align: left;
}

.navigation-section .list-section {
    list-style-type: none;
}

.list-section {
    padding-left: 20px;
}

.navigation-section .list-section img {
    margin-right: 0.9rem;
    width: 13px;
    height: 7px;
}

.nested-list {
    margin: 1.2rem auto 1.5rem;
    padding-left: 5px;
}

.nested-list li {
    list-style-type: none;
    display: flex;
    align-items: center;
    border-left: 2px solid #CCCCCC;
    padding-left: 15px;
    height: 2.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: #212121;
}

.highlight {
    position: relative;
}

.highlight::before {
    content: "";
    /* width: 3px; */
    height: 2.5rem;
    border: 2px solid #122549;
    position: absolute;
    left: -1.5%;
    border-radius: 5px;
}

.navigation-conatiner .header-text {
    font-size: 1.1rem;
    color: var(--text-21);
    font-weight: 600;
}

.navigation-conatiner .check-icon {
    width: 20px !important;
    height: 9px !important;
    margin-right: 0.5rem !important;
}