.paycraft-table .top-filter {
  margin: 1rem;
}

.paycraft-table .ant-table-thead>tr>th {
  background: #e4e9f2 0% 0% no-repeat padding-box;
  color: #767676;
  font-weight: 600;
}

.paycraft-table table {
  border-spacing: 0 0.8rem !important;
}

.paycraft-table .ant-table-tbody tr td,
.paycraft-table .ant-table-tbody>tr.ant-table-row:hover>td {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #454545;
  padding: 11.75px 16px;
}

.paycraft-table .ant-table {
  background: none !important;
}

.paycraft-table .ant-table table {
  border-collapse: collapse;
}

.paycraft-table .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: none !important;
}

.paycraft-table .top-filter {
  display: flex;
  justify-content: space-between;
}

.rotate span {
  transform: rotate(90deg);
  color: #4b5660;
  font-weight: 600;
}

.top-filter .flt-btns button,
.more {
  height: 2.8rem;
  margin-right: 1rem;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 6px;
  color: #2e2e2e !important;
}

.more.reports {
  height: 2.4rem;
}

.more.reports img {
  width: 1.2rem;
}

.top-filter .flt-btns button span {
  color: #4b5660;
  font-weight: 500;
  font-size: 0.9rem;
}

.top-filter .flt-btns button.active {
  background-color: var(--primary-color);
}

.top-filter .flt-btns button.active span {
  color: #fff;
  border: none;
  font-size: 0.9rem;
}

.status.pending {
  color: #f6a907;
  background: #fef4de 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
  text-align: center;
  display: inline-block;
  height: 2.7rem;
}

.ant-table-cell .status-line {
  position: relative;
}

.tableFilter span {
  color: #2e2e2e;
}

.paycraft-table .ant-table-tbody tr:hover {
  background-color: #fff;
}

.paycraft-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.paycraft-table.overflow {
  overflow: auto;
}