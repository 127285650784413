.business-registration-container .business_upload div:first-child {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    width: 100%;
}

.business-registration-container .buttonUpload .business_upload div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.business-registration-container .business_upload .upload-documents-section span:first-child {
    width: 100%;
}

.business-registration-container .business_upload .upload_file .upload_buttom {
    width: 11.12rem;
}

.business-registration-container .business_upload .upload-documents-section-label {
    font-size: 14px;
    color: rgb(59, 59, 59);
}