.fontWeight-normal {
  font-weight: normal !important;
}

.fontWeightBold {
  font-weight: bold;
}

.fontSize2rem {
  font-size: 2rem !important;
}


.withoutBgButton {
  color: #f6a907;
  background: transparent 0% 0% no-repeat padding-box;
  padding: 0.5em 1.5em;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  text-transform: capitalize;
  text-align: center;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  height: auto;
}

.cardProfile {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 18px;
  line-height: 2.3;
  padding: 24px;
  margin-bottom: 6px;
}

.profileCardLeftSideTitle {
  color: #00000099;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  opacity: 0.7;
}

.profileCardRightSideName {
  color: #141414f4;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.colorCodeEF951C {
  color: #ef951c;
}

.site-collapse-custom-collapse {
  background-color: white !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #4b5660;
  opacity: 0.8;
}

.addNewRowButton {
  color: var(--primary-color) !important;
  border: none !important;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  margin-right: 5px;
  font-size: 12px;
  width: 100px;
}

.deleteRowButton {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: red;
  color: white;
  padding: 7px;
}

.inputDivTag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  flex: 0.1;
}
.poppinsNormalFont {
  font-family: "Poppins", sans-serif;
  color: #58666e;
  font-weight: normal;
}
.twelveFontSize {
  font-size: 12px;
}
.fourteenFontSize {
  font-size: 14px;
}

.inputDivTag label {
  margin-right: 20px;
}
.inputDivTag label.spend-limits {
  width: 5.5rem;
}
.inputDivTag label.max-limits {
  width: 4rem;
}
.alignItemsToFlexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.inputTag,
.card-info .ant-select-selector {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  color: #2e2e2e !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: bold;
  width: 130px !important;
  padding: 10px !important;
}
.card-info .ant-select {
  width: 130px !important;
}

.card-info .ant-select-selector {
  height: 3rem !important;
}

.card-info .ant-select-selection-item {
  bottom: 5px;
  padding-bottom: 45px;
}
.inputDivTag .ant-input-disabled,
.inputDivTag .ant-input-prefix {
  color: #2e2e2e !important;
  font-weight: bold;
}

.inputTag::placeholder {
  font-weight: normal;
}
.inputTag:focus {
  box-shadow: none !important;
}
.dropdowntag {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
}

.dropdowntag > span,
.ant-dropdown-menu-item > span {
  color: #2e2e2e !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
  padding: 3px;
  font-weight: 500;
}

.ant-dropdown-menu-item-active > span:hover {
  color: #3f81f4 !important;
}

.informationIcon {
  color: white;
  background-color: black;
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 10px;
  font-size: 12px;
}
.minKycDiv {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
}
.F48C32ButtonColor {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.verifiedColor {
  color: #1cb110 !important;
}
.opacity05 {
  opacity: 0.5;
}
.card-info-flex-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.titleColorCode333333 {
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.expensesAmount {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #4d4d4d;
}
.flex07 {
  flex: 0.7;
}
.flex03 {
  flex: 0.3;
}
.accordion-heading,
.accordion-heading .ant-collapse-header-text {
  font-size: 1.4rem;
  color: #333333 !important;
}

.ant-collapse-header-text {
  color: #333333 !important;
}
.kycImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 20px;
}
.kycImageName {
  color: #00000099;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  opacity: 0.6;
  margin-bottom: 10px;
}

.cardImage {
  width: 20rem;
}

.panelButton {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.68);
  font-weight: bold;
  padding-left: 20px;
}

.panelIcon {
  float: right;
  color: rgba(0, 0, 0, 0.68);
  padding-right: 15px;
}

.modalContent h3 {
  color: var(--bg-primary);
}

.modalContent span {
  color: rgba(0, 0, 0, 0.68);
}

.transactionMenu .anticon-caret-right {
  float: right;
}

.debit-card {
  padding: 1rem;
  border-radius: 5px;
  background-color: #323232;
  margin: 1.5rem;
  min-width: 22rem;
}

.debit-card h2 {
  font-size: 1.1rem;
  color: #fff;
}

.card-usage {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
}

.card-usage .item {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}
.card-usage .item div span {
  display: block;
  font-size: 0.9rem;
}
.card-usage .item div span:first-child {
  color: #333333;
}

.card-usage .item div span:last-child {
  color: #33333380;
  font-size: 0.8rem;
}

.right-alinged-modal.lg {
  width: 54rem !important;
}

.card-usage .switch {
  margin-left: 0.5rem;
}

.btn-menu-wrap {
  margin-right: 1rem;
}

.btn-menu-wrap .ant-btn {
  height: 2.8rem;
}

.card_ref {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_ref span {
  font-weight: 600;
  color: #333333;
}

.card_ref span:last-child {
  font-weight: 400;
}

.rbi {
  color: #454545;
  font-weight: 400 !important;
  margin-top: 0.5rem;
  display: inline-block;
}
.message {
  padding: 0 1rem;
}

.card-activation ul {
  list-style: none;
}

.card-activation ul span {
  display: block;
  color: #454545;
  font-size: 0.9rem;
}

.card-activation ul .title {
  color: #4a4a4a;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.card-activation .flex-start {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0rem;
}

.card-activation li {
  margin-bottom: 0.7rem;
}

.card-activation ul {
  background: #edf0f5;
  border-radius: 10px;
  padding: 2rem;
}
