.dashboard .head h2,
.report-summary h2 {
  color: #4b5660;
  font-size: 1.5rem;
}
.report-summary h2 {
  font-size: 1.2rem;
  border-bottom: 1px solid #f7faef;
  padding-bottom: 0.8rem;
}
.dashboard .head h2 span {
  color: #818181;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.dashboard .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.dashboard span.active-text,
.report-title span:first-child {
  color: #3f81f4;
  font-weight: 600;
  font-size: 1rem;
}

.card-sec .summary-title {
  color: #4b5660;
  font-size: 1.1rem;
  font-weight: 600;
}
.gap1 {
  gap: 1.5rem;
}

.dashboard .debit-card {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-width: 80%;
}

.high-small label {
  color: #4b5660;
  font-weight: 600;
  font-size: 0.8rem;
  display: block;
}

.high-small span {
  color: #454545;
  font-weight: 600;
  font-size: 1.5rem;
}

.dashboard .high-small {
  margin-top: -6.5rem;
}

.high-medium label {
  color: #4b5660;
  font-weight: 600;
  font-size: 0.8rem;
  display: block;
  opacity: 0.6;
}

.high-medium span,
.report-summary span,
.report-title span:last-child {
  color: #00000099;
  font-size: 0.8rem;
}

.high-medium span strong,
.report-summary span strong {
  color: #00000099;
  font-weight: 600;
  font-size: 1.1rem;
}

.dashboard .high-medium {
  margin-bottom: 1rem;
}

.dashboard aside button {
  font-size: 0.9rem;
}

.tasks {
  background: #f7f7fb 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 5px;
  padding: 1.3rem 0;
}

.tasks div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 2.5rem;
}

.tasks div label,
.tasks div span {
  color: #00000099;
}

.tasks span strong {
  margin-right: 0.5rem;
}

.quick-links .one,
.quick-links .two,
.quick-links .three {
  background: #fffaf080 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 5px;
  padding: 2rem;
  width: 33.3%;
  color: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
}
.quick-links .two {
  background: #fff5f0 0% 0% no-repeat padding-box;
  border-radius: 0px;
}
.quick-links .three {
  background: #f7faef 0% 0% no-repeat padding-box;
  border-bottom-right-radius: 5px;
}

.quick-links .one span,
.quick-links .two span,
.quick-links .three span {
  display: block;
  border-radius: 50%;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.no-card .quick-links .one,
.no-card .quick-links .two,
.no-card .quick-links .three {
  flex-direction: column;
  min-height: 12.2rem;
}

.no-card .tasks {
  border-radius: 0px 0px 18px 18px;
}
.report-summary span {
  display: block;
}

.report-summary span strong {
  margin-right: 0.3rem;
}

.report-summary .d-flex div {
  padding: 0.3rem 1rem;
  margin-left: 1rem;
  padding-bottom: 0.8rem;
}

.report-summary .d-flex div span:first-child {
  color: #4b5660;
  font-weight: 600;
}

.report-summary .d-flex div.active span,
.report-summary .d-flex div.active span strong {
  color: var(--primary-color) !important;
}

.report-summary .d-flex div.active {
  border-bottom: 3px solid var(--primary-color);
}

.dashboard-box .ant-select-selector,
.dashboard-box .ant-select-focused .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
.dashboard-box .ant-select-selection-item {
  font-weight: 600;
}

.title-between .dashboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
