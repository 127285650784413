.search-input input {
  height: 2.8rem;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 0.5rem;
}

.search-input {
  position: relative;
}

.paycraft-table .search-input input {
  margin-right: 1rem;
  width: 20rem;
}

.search-input input::placeholder {
  color: #b9b9b9;
}

input {
  outline: none;
}

.search-input img {
  position: absolute;
  right: 22px;
  top: 11px;
}
