@import url(./assets/css/shimmer.css);
@import url(./components/Advances/advances.css);
@import url(./components/OnBoarding/Auth/BusinessKYC/business.css);
@import url(./components/Purchases/Receipt/receipts.css);

#root {
  background: #f5f7fa;
  max-width: 1920px;
  margin: auto;
}

.text-dim {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-small {
  color: #818181;
  font-size: 12px;
  font-weight: 300;
}

.steps-align {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.steps-align .steps {
  width: 30%;
}

.steps-align.upload-steps .steps {
  width: 45%;
}

.template_active {
  border: 1px solid grey;
  height: 300px;
  width: 185px;
}

.template_border {
  height: 300px;
  width: 185px;
}

.template_active img {
  height: 100%;
  width: 100%;
}

.template_border img {
  height: 100%;
  width: 100%;
}

.footer {
  display: none;
}

.template_border:hover .footer {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template_border.hover .footer {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template_active:hover .footer {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  z-index: 1000;
  background: var(--bg-light);
}

.fixed-nav {
  position: sticky;
  top: 0;
  left: 0;
  margin-left: 1rem;
  z-index: 999;
}

.fixed-content {
  position: relative;
  padding: 1rem;
}

.flex-row {
  flex-direction: row !important;
}

.ant-table-cell {
  color: #454545;
  font-weight: 600;
}

.status {
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 1rem;
}

.status::before {
  position: absolute;
  content: "";
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  left: 0.7rem;
  top: 46%;
}

.status_pending_approval,
.status_payment_pending,
.status_partially_paid,
.status_in_progress,
.status_saved,
.status_pending,
.status_InProgress {
  color: #f6a907;
  /* background: #fef4de; */
}

.status_payment_pending::before,
.status_pending_approval::before,
.status_partially_paid::before,
.status_unsubmitted::before,
.status_pending::before,
.status_saved::before,
.status_in_progress::before,
.status_InProgress::before {
  background-color: #f6a907;
}

.status_pending_reimbursement,
.status_pending_recovery,
.status_link_created,
.status_recovered,
.status_reimbursed {
  color: #45a0fe;
  /* background: #e1f0ff; */
}

.status_pending_reimbursement::before,
.status_link_created::before,
.status_pending_recovery::before,
.status_recovered::before,
.status_reimbursed::before {
  background-color: #45a0fe;
}

.status_approved,
.status_success,
.status_confirmed,
.pay-table .status_closed,
.status_accepted,
.status_paid,
.status_payment_success,
.status_active,
.status_reported {
  color: #35aa09;
}

.status_approved::before,
.status_closed::before,
.status_confirmed::before,
.status_success::before,
.pay-table .status_closed::before,
.status_accepted::before,
.status_payment_success::before,
.status_paid::before,
.status_active::before,
.status_reported::before {
  background-color: #35aa09;
}

.status_rejected,
.status_declined,
/* .status_closed, */
.status_failed,
.status_FAILURE,
.status_cancelled {
  color: #f15363;
}

.status_rejected::before,
.status_failed::before,
.status_declined::before,
/* .status_closed::before, */
.status_FAILURE::before,
.status_cancelled::before {
  background-color: #f15363;
}

.status_text_pending_approval {
  color: #f6a907;
}

.status_text_pending_reimbursement {
  color: #45a0fe;
}

.status_text_approved {
  color: #35aa09;
}

.status_text_rejected {
  color: #f15363;
}

.premission-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.pp-main-button {
  display: flex !important;
}

.ant-pagination-total-text {
  color: black;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-left: none !important;
}

.ant-btn-rtl img {
  margin-left: 10px;
}

.rtl .sub-menu .sub-menu-children {
  right: 1.4rem;
  left: auto;
}

.rtl .sub-menu .sub-menu-children:before {
  right: 5px;
  left: auto;
}

.rtl .sub-menu .sub-menu-children .sub-menu-children-item.sub-menu-selected {
  right: 1.5rem;
  left: auto;
}

.rtl .ml1 {
  margin-left: auto;
  margin-right: 1rem;
}

.rtl .mr1 {
  margin-left: 1rem;
  margin-right: auto;
}

.rtl .pp-dropdownbutton button:nth-child(2) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rtl .pp-dropdownbutton button:nth-child(1) {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.rtl .no-dd button:nth-child(1) {
  border-radius: 10px !important;
}

.rtl .rtl-text-right {
  text-align: right;
}

.lang .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.lang .ant-select-selection-item {
  color: #454545;
  font-weight: 600;
}

.menu-item {
  position: relative;
}

.menu-item .line {
  width: 100%;
  height: 1px;
  background: #ffffff26;
  position: absolute;
  bottom: -1.5rem;
  left: 0px;
}

.menu-item.extra-margin {
  margin-bottom: 3rem !important;
}

.titleLabel:not(:nth-child(1)) {
  margin-top: 2rem;
}

.onBoard-layout {
  min-height: 100vh;
  background: #ffffff;
  padding: 2.5rem 2.1rem;
}

.onBoard-layout .ant-layout {
  background: #ffffff;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.mb1 {
  margin-bottom: 1rem;
}

.otpInputBlock {
  display: flex;
  justify-content: center;
}

.otpInputBlock .otpInputField {
  margin-right: 16px;
  width: 45px;
  position: relative;
  margin-bottom: 0px;
  margin-top: 1rem;
  text-align: center;
}

.otpInputBlock .otpInputField>input {
  padding: 0;
  height: 45px;
  width: 45px;
  text-align: center;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otpInputBlock .otpInputField:last-child {
  margin-right: 0;
}

.otpInputBlock .otpInputField>input:focus {
  border: 1px solid #002e68;
}

.payment-details {
  background-color: rgb(245, 247, 250);
  padding: 30px 27px;
  margin-top: 20px;
  font-size: 17px;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.timeLeft {
  color: #000001;
  font-weight: 600;
  margin-top: 10px;
}

.resendOtp {
  color: #000001;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

/* image preview blur CSS file */
.image-preview-blur {
  filter: blur(1px);
  transition: filter 0.3s ease;
}

@import url(./components/Analytics/anatytics.css);