.setting-nav {
  background: var(--text-white) !important;
  margin: 0 1rem 0 0 !important;
  border-radius: 7px;
}

.setting-nav .ant-menu-title-content {
  color: var(--text-primary);
}

.setting-nav .ant-menu-sub {
  background: var(--text-white);
}

.setting-nav .ant-menu-item {
  background: var(--text-white);
  margin: 0px !important;
}

.right-alinged-modal {
  float: right;
  top: 0px !important;
  width: 45rem !important;
  height: 100vh;
}

.right-alinged-modal .ant-modal-content {
  min-height: 100vh;
}

.right-alinged-modal .ant-modal-footer .ant-btn span {
  color: var(--text-primary);
}

.right-alinged-modal-small {
  float: right;
  top: 0px !important;
  width: 35rem !important;
  height: 100vh;
}

.right-alinged-modal-small .ant-modal-content {
  min-height: 100vh;
}

.right-alinged-modal-small .ant-modal-footer .ant-btn span {
  color: var(--text-primary);
}

.right-alinged-modal .ant-modal-header,
.right-alinged-modal-small .ant-modal-header {
  padding: 20px 24px 10px 24px !important;
}

.center-aligned-modal-big {
  width: 80em !important;
}

.settings_form .ant-checkbox-wrapper span,
.settings_form .ant-radio-wrapper span,
.settings_form .ant-form-item-label label {
  color: var(--text-primary) !important;
}

.settings_form .ant-form-item-control {
  width: 80%;
}

.settings_form .ant-upload-drag {
  max-height: 6rem;
  max-width: 26rem;
  background: var(--text-white);
  border: 2px dashed var(--text-d9) !important;
  margin: 2rem 0rem;
}

.uploadSectionSmall .ant-upload-drag {
  margin: 1rem 0rem;
}

.modal-form .ant-checkbox-wrapper span,
.modal-form .ant-radio-wrapper span,
.modal-form .ant-form-item-label label {
  color: var(--text-primary) !important;
}

.setting-row {
  max-width: 95%;
}

.hint {
  color: rgba(0, 0, 0, 0.45);
}

.uploadSection {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 40% !important;
}

.uploadSectionSmall {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 40% !important;
  padding: 0px 1rem;
}

.ant-upload-text {
  font-size: 13px !important;
  padding: 0 3rem;
}

.category_type .ant-radio-wrapper span {
  color: var(--text-primary) !important;
}

.category_type {
  margin-bottom: 2rem !important;
}

.modal-form .ant-collapse {
  border: none;
  border-radius: 0px;
}

.modal-form .ant-collapse-item {
  background: var(--text-white) !important;
}

.modal-form .anticon {
  float: right;
}

.modal-form p {
  color: var(--text-primary) !important;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.modal-form .ant-checkbox-wrapper {
  float: right;
  margin-right: 2rem;
}

.noFloat .ant-checkbox-wrapper {
  clear: both;
  float: left;
  margin-bottom: 10px;
}

.noFloat .ant-collapse-content>.ant-collapse-content-box {
  margin-bottom: 20px;
}

.contentWrapper {
  background: var(--text-white);
  border-radius: 0.5rem;
  padding: 2rem 3rem 3rem 3rem;
}

.setting-nav .ant-menu-submenu .ant-menu-item-selected .ant-menu-title-content {
  background: #f4f4f4;
  border-radius: 5px;
  margin: 0px 15px 0px -15px;
  padding-left: 15px;
}

.setting-nav .ant-menu-item-selected .ant-menu-title-content {
  background: #f4f4f4;
  border-radius: 5px;
  margin: 0px 0px 0px -26px;
  padding-left: 35px;
}

.setting-nav .ant-menu-item-selected .menu-logo {
  z-index: 10;
}

.setting-nav .ant-menu-item-selected {
  background-color: var(--text-white) !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

.content-div {
  padding-top: 10px;
  grid-area: content;
}

.setting-tabs .ant-tabs-nav {
  margin-bottom: 0px !important;
}

.setting-tabs .ant-tabs-tab {
  padding: 1rem !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  margin: 6px 2px !important;
}

.setting-tabs .ant-tabs-tab-active {
  border-top: 5px solid orange !important;
  margin-bottom: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.setting-tabs .ant-tabs-content-holder {
  background: var(--text-white);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 2rem;
  margin: 0px 2px;
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.pageHeader p:nth-child(1) {
  color: #262626;
  font-size: 2rem;
  margin: 0;
  font-weight: 700;
}

.pageHeader p:nth-child(2) {
  color: #767676;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
}

.pageHeader .ant-typography,
.pageHeader .ant-breadcrumb {
  display: inline-block;
}

.pageHeader .ant-breadcrumb {
  margin-left: 2rem;
}

.addNew {
  float: right;
  background: var(--primary-color) !important;
  border-radius: 0.3rem !important;
}

.addNew button {
  background: var(--primary-color) !important;
}

.addNew .ant-btn-default {
  background: var(--primary-color) !important;
  border-bottom-left-radius: 0.3rem !important;
  border-top-left-radius: 0.3rem !important;
}

.addNew .ant-dropdown-trigger {
  background: var(--bg-orange-light) !important;
  border-bottom-left-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

.formButton {
  background: var(--primary-color) !important;
  border-radius: 0.3rem !important;
  text-transform: capitalize;
}

.cancelButton {
  border-radius: 0.3rem !important;
}

.cancelButton span {
  color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-modal-footer {
  display: none;
}

.exp-table .ant-modal-footer{
  display: block !important;
}
.settingsTable .ant-table-content table {
  border-spacing: 0 8px;
}

.settingsTable .ant-table {
  background: transparent;
}

.settingsTable .ant-table-thead .ant-table-cell {
  background: #e4e4e4;
}

.settingsTable .ant-table-container table>thead>tr>th {
  padding: 12px 16px;
}

.settingsTable .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.settingsTable .ant-table-container table thead th.actions {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.settingsTable .ant-table-tbody>tr>td {
  padding: 12px 16px;
  background: var(--text-white);
}

.settingsTable .ant-table-tbody>tr>td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.settingsTable .ant-table-tbody>tr>td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.settingsTable .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(0.5) translate(-145%, -60%);
}

.settingsTable .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 3px solid #fff;
  border-top: 0;
  border-left: 0;
  top: 3px;
  left: 2px;
}

.usersTable .ant-table-tbody>tr>td:first-child::before {
  content: "";
  position: absolute;
  width: 3px;
  left: 0px;
  height: 60%;
  background-color: #f6a907;
  top: 13px;
  border-radius: 10px;
}

.settingCard {
  background: transparent !important;
}

.settingCard .ant-card-body,
.settingCard .ant-card-head .ant-card-extra {
  padding: 0;
}

.settingCard .ant-card-head {
  border: none;
  padding: 0;
}

.settingCard .ant-card-head .ant-card-extra {
  border: none;
  width: 100%;
}

/* .settingCard .ant-card-head .ant-card-extra .buttonsRight span, */
.settingCard .ant-card-head .ant-card-extra .buttonsLeft span {
  color: var(--text-primary) !important;
}

.settingCard .ant-card-head .ant-card-extra .buttonsLeft .active span {
  color: var(--text-white) !important;
}

.settingCard .ant-card-head .ant-card-extra .buttonsLeft {
  float: left;
}

.settingCard .ant-card-head .ant-card-extra .buttonsRight {
  float: right;
}

.checkboxes span {
  color: var(--text-primary) !important;
}

.lightText {
  color: var(--text-c6) !important;
}

.tableStatus_Deleted {
  color: #f6a907;
  background: #fef4de;
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
}

.tableStatus_Active,
.tableStatus_null {
  color: var(--text-2e);
  background: var(--text-e1);
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
}

.tableRowCircle {
  background: var(--text-e6);
  width: 35px;
  height: 35px;
  border-radius: 40px;
  text-align: center;
  line-height: 35px;
}

.detailsCircle {
  background: var(--text-e6);
  width: 45px;
  height: 45px;
  border-radius: 40px;
  text-align: center;
  line-height: 45px;
}

.action-logo {
  width: 1.8rem;
}

.imgWrapper .action-logo {
  width: 1.2rem;
}

.addNew-logo {
  width: 1rem;
  margin-right: 0.5rem;
  margin-top: -3px;
}

.floatRight {
  float: right;
  margin-top: 5px;
}

.custom-field .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: flex-start !important;
}

.floatRightDiv {
  float: right;
}

.floatRightDiv .ant-form-item {
  margin-bottom: 0px !important;
}

.userDetailHead span {
  color: var(--text-76);
}

.orgaImage {
  width: 15rem !important;
}

object.orgaImage {
  overflow: hidden !important;
}

.filterButtons {
  border-radius: 5px !important;
  background: var(--bg-light) !important;
  border: 1px solid #d6d6d6 !important;
  font-size: 14px !important;
}

.filterButtons.active {
  background: var(--primary-color) !important;
}

.searchBox input {
  background: var(--bg-light);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchBox .ant-input-search-button {
  background: var(--bg-light);
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.dividerText {
  background: #f4f4f4;
  height: 40px;
  font-weight: 600;
  margin: 0px -24px;
  padding-top: 8px;
  padding-left: 25px;
}

.table-placeholder {
  text-align: center;
}

.table-placeholder .empty-normal {
  padding: 55px 16px;
  margin: 55px 0;
  background: var(--text-white);
  border-radius: 8px;
}

.table-placeholder .empty-description {
  color: rgba(0, 0, 0, 0.25);
}

.stepsNumber {
  background: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  line-height: 20px;
  margin-left: 2px;
}

.ant-steps-item-tail::after {
  background-color: var(--primary-color) !important;
  margin-top: 9px;
}

.approve-form .ant-form-item {
  display: inline-block;
  margin-right: 15px;
}

.disabledDiv {
  background-color: var(--text-disable);
  padding: 9px 20px;
  border-radius: 5px;
  min-width: 181px;
  font-weight: 500;
  display: inline-block;
}

.approvalsCheckbox {
  display: inline-flex;
  margin-left: 20px;
}

.approvalsCheckbox .ant-checkbox-wrapper {
  margin-top: 4px !important;
}

.approvalsCheckbox .ant-checkbox-wrapper span {
  color: var(--text-primary) !important;
}

.approve-add-btn {
  box-shadow: none !important;
}

.approve-add-btn span {
  color: var(--primary-color);
}

.select-role .ant-select-selector {
  min-width: 181px;
}

.floatBottom {
  margin-top: 100px;
}

.select-role {
  width: 181px;
}

.width-80 {
  width: 80% !important;
}

.customCollapse .ant-collapse>.ant-collapse-item {
  border-bottom: none;
}

.rtl .approvalsCheckbox {
  margin-right: 20px;
  margin-left: 0px;
}

.rtl .settingsTable .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rtl .settingsTable .ant-table-container table>thead>tr:last-child th:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rtl .settingsTable .ant-table-tbody>tr>td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rtl .settingsTable .ant-table-tbody>tr>td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.draggable .ant-table table {
  border-spacing: 0 12px;
}

.draggable .ant-table-measure-row {
  visibility: hidden;
}

.settingsTable.draggable .ant-checkbox-inner::after {
  top: 3px;
  left: 1.5px;
}

.hierarchical-select {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--text-db);
  border-radius: 10px;
  margin-top: 2rem;
  cursor: pointer;
}

.hierarchical-select div span:first-child {
  font-size: 1.2rem;
  color: #262626;
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-weight: 600;
}

.hierarchical-select div span:nth-child(2) {
  font-size: 1rem;
  color: #767676;
  display: block;
  margin-left: 1rem;
}

.ant-radio-checked::after {
  border: 1px solid #3f81f4 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #3f81f4 !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #3f81f4 !important;
}

.hierarchical-select.active {
  background-color: #3f81f40f;
  position: relative;
}

.hierarchical-select .anticon-arrow-right svg {
  width: 30px;
  height: 12px;
  fill: #3f81f4;
  position: absolute;
  right: 10px;
  top: 45%;
}

/* advanced Approval css start  */

.table-section.settingsTable .ant-table-tbody>tr>td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #dbdbdb;
  border-right: none;
}

.table-section.settingsTable .ant-table-tbody>tr>td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #dbdbdb;
  border-left: none;
}

.table-section.settingsTable .ant-table-tbody>tr>td {
  border-top: 1px solid #dbdbdb !important;
  border-bottom: 1px solid #dbdbdb !important;
}

.approval-section .header-text {
  font-size: 1.1rem;
  color: #4b5660;
  font-weight: bold;
}

.approval-section .sub-header-text {
  font-size: 1rem;
  color: #333333;
  font-weight: 500;
  opacity: 0.7;
  padding: 1rem 1.25rem;
  border: 1px solid #d0d0d0;
  border-radius: 7px;
  display: block;
  margin-bottom: 1rem;
}

.approval-section .approval-change-btn {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.approval-section .approval-change-btn span {
  color: #727272;
  font-size: 1rem;
  font-weight: 500;
}

.approval-section .approval-change-btn.ant-btn:hover,
.approval-section .approval-change-btn.ant-btn:focus {
  background: #fafafa;
  border: 1px solid #d9d9d9;
}

.nameItem .ant-row {
  display: block !important;
}

.county_disable .ant-select-selection-item {
  opacity: 0.4;
}