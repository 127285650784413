.grid-container {
    display: grid;
    max-width: 400px !important;
    min-width: 400px !important;
    width: 400px !important;
    grid-template-columns: auto auto;
}

.integration .integration-card {
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    min-width: 16rem;
    max-width: 16rem;
    position: relative;
    cursor: pointer;
    min-height: 13rem;
}

.integration .integration-card.disabled {
    opacity: 0.5;
    /* pointer-events: none; */
  }


  .integration-card .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.integration-card h2 {
    margin-top: 0;
    margin-bottom: 0 !important;
    color: #122549;
    opacity: 1;
    font-size: 1.2rem;
}

.integration-card .card-image {
    max-width: 10rem;
}

.integration-card  p {
    margin-bottom: 0;
    color: #122549;
    opacity: 0.5;
    font-size: 1rem;
}

.banner {
    border-radius: 6px 0 0 0;
    padding: 0.3rem 0.8rem;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
}

.banner.active {
    background: #E1F8D9 0% 0% no-repeat padding-box;
    color: #2EA700;
}

.banner.inactive {
    background: #FFC0CB;
    color: #FF0000;
}


.connect {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.connect button {
    background: #3F81F4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: 1.1rem;
}

.user-info {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
}

.details p {
    font-size: 1.1rem;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.vertical-line {
    height: 1rem;
    border-left: 1.8px solid #212121;
    margin: 0 1rem;
}

.button-container button {
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0px;
    color: #3F81F4;
    opacity: 1;
}

.user-info p,
h5 {
    margin-bottom: 0;
}

.strip {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
}

.strip.reject {
    padding: 1rem;
    background-color: #d14046;
    color: #faeef1;
}

.strip.success {
    background-color: #36AF47;
    color: #FFFFFF;
}

.sync-data {
    min-height: calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.history {
    color: #3F81F4 !important;
    position: relative;

}

.history::before {
    height: 2px;
    background-color: #3f81f4;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    content: "";
}

.custom-steps .ant-steps-item-title:hover {
    color: #3f81f4 !important;
}

.custom-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: #F4F7FA;
}

.datamodels-warning {
    background: #F5EDC3 0% 0% no-repeat padding-box;
    border-radius: 13px;
    opacity: 0.5;
    padding: 0.2rem 1.2rem;
    margin: 1rem;
}

.datamodels-warning p {
    color: #4B5660;
    opacity: 0.72;
}