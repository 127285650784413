
.typeDiv {
    border: 1px solid #D97D00;
    color: #D97D00;
    border-radius: 5px;
    text-align: center;
    padding: 4px 9px;
}
.viewPolicyCards {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 9px;
    margin-top: 10px;
}
.darkbox{
    background: #D0D0D0;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    display: inline-block;
    padding: 6px 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 100px;
    text-align: center;
}

.lightbox{
    background: #E5E5E5;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    display: inline-block;
    padding: 6px 10px;
    margin-left: 10px;
    min-width: 100px;
    width: 15rem;
    text-align: center;
}

.viewPolicyCards .ant-select {
    width: 170px;
    margin-top: 10px;
}

.viewPolicyCards .ant-input {
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
}
