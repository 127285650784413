.cart {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #ed3e430a;
  border: 1px solid #edf0f5;
  margin: 2rem;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
}

.cart .d-flex {
  gap: 1rem;
}

.close {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  z-index: 999;
}

.close img {
  width: 1.5rem;
  height: 1.5rem;
}

.cart .ant-steps-item-icon {
  display: none;
}

.cart .ant-steps {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 28px;
  padding: 0.5rem 3rem;
  width: 70%;
  margin: 1rem auto;
}

.cart .ant-steps-item-title {
  font-weight: 600;
  color: #9e9e9e;
  text-transform: uppercase;
  cursor: pointer;
}

.cart
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #9e9e9e80;
}

.cart
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #9e9e9e80;
}

.cart .ant-steps-item-finish .ant-steps-item-title,
.cart .ant-steps-item-active .ant-steps-item-title {
  color: #3f81f4 !important;
}

.cart
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #3f81f4 !important;
}

.cart .header h3 {
  color: #2c2c2c;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.cart .header .note {
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 1rem;
}

.price-details ~ .input-wrap label {
  color: #222222 !important;
  font-weight: 600;
  margin-bottom: 0.5rem !important;
  display: inline-block;
}

.cart .header p.early-bird {
  background: #f8fafc 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  padding: 0.5rem 1rem;
  color: #262626;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.cart .header p.early-bird span {
  color: #262626;
}

.cart .header p.early-bird b {
  padding: 0 1rem;
  text-transform: uppercase;
}

.cart .table {
  margin: 0;
  margin-bottom: 2rem;
}

.cart .table .tbody .td {
  font-weight: 600;
}

.cart .total strong {
  color: #262626;
  padding: 0 0.5rem;
}

.cart .total {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 23px;
  padding: 0.7rem 3rem;
  display: flex;
  justify-content: flex-end;
}

.cart .total .note {
  margin-top: 0;
}

.cart .right button,
.confirm,
.view-btn {
  color: white;
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 0.7rem 0;
  width: 100%;
  font-weight: 600;
}

.confirm:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.container-capacity,
.price-details {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 0.3rem 1rem;
  margin-bottom: 1.5rem;
}

.cart-popup .container-capacity {
  border: none;
}

.container-capacity h3,
.price-details h3 {
  color: #262626;
  font-weight: 600;
  border-bottom: 1px solid #cccccc80;
  padding: 0.7rem 0;
  width: 100%;
}

.container-capacity h3 span {
  color: #4b5acc;
  font-size: 0.8rem;
}

.container-capacity strong,
.container-capacity b {
  font-size: 0.8rem;
}

.container-capacity .boxes div {
  display: flex;
  column-gap: 0.2rem;
}

.container-capacity .boxes div span {
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  border: 1px solid #eaeaea;
}

.container-capacity .boxes div span.active {
  background: #e6a742 0% 0% no-repeat padding-box;
}

.container-capacity .boxes {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0.1rem;
  flex-wrap: wrap;
  align-items: center;
}

.change_addr {
  cursor: pointer;
  text-decoration: underline;
}

.cart-popup .boxes {
  padding: 0.5rem 0rem;
}

.container-capacity .boxes label {
  color: #3e3e3e80;
  font-size: 0.8rem;
}

.price-details span {
  color: #3e3e3e80;
}

.price-details span strong {
  color: #262626;
}

.price-details {
  padding-bottom: 1.5rem;
}

.addresses label {
  color: #1f1f1f;
  text-transform: uppercase;
  opacity: 0.62;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  font-size: 1.1rem;
}

.address {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.order-placed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address span.addr,
.address-note,
.order-placed p {
  color: #2e2e2e80;
  margin-left: 1rem;
  font-weight: 600;
}

.addresses .check {
  background-color: #2c2c2c;
}

.diff-location {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.address-note {
  margin: 0;
  margin-left: 0.5rem;
}

.summary-terms .address {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
}

.agree-link {
  color: #1f1f1f;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 1rem;
}

.agree-link .terms-conditions {
  text-decoration: underline;
  color: #3f81f4;
}

.confirm {
  width: 20rem;
}

.order-placed h3,
.order-placed .link {
  color: #2c2c2c;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.order-placed p {
  width: 40%;
  text-align: center;
}

.order-placed .link {
  text-decoration: underline;
  font-size: 0.9rem;
}

.btns {
  display: flex;
  justify-content: center;
}

.hm-btn {
  border: 1px solid #383838;
  border-radius: 4px;
  color: #383838;
  font-weight: 600;
  padding: 0 2rem;
}

.view-btn {
  width: 10rem;
  margin-left: 1rem;
}

.view-details .ant-table-cell {
  text-align: center;
}

.view-details .ant-table-thead .ant-table-cell {
  border-left: 1px solid #707070 !important;
  /* border-top: 1px solid #707070 !important; */
}

.view-details .ant-table-tbody .ant-table-cell {
  border-left: 1px solid #707070 !important;
}

.view-details .ant-table-content,
.view-details .ant-table-thead .ant-table-cell {
  background: #efefef 0% 0% no-repeat padding-box;
}

.view-details .ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #707070 !important;
}

.view-details .ant-table-content {
  border-right: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
  border-top: 1px solid #707070 !important;
}

.view-details h2 {
  color: #1f1f1f;
  text-transform: uppercase;
}

.quantity .link {
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 0.5rem;
  cursor: pointer;
}

.quantity {
  display: flex;
  align-items: center;
}

.quantity span {
  color: #454545;
}

.quantity .action-td {
  width: 40%;
}

.quantity img {
  margin-left: 0.5rem;
  cursor: pointer;
}

.jc-space-between {
  justify-content: space-between;
}

.quantity input {
  border-radius: 4px;
}

.bg-green {
  background-color: green !important;
}

.cart-input input,
.quantity input {
  border: #c7c7c7 1px solid;
  height: 2.6rem;
  width: 6rem;
  border-radius: 5px;
  padding-left: 0.5rem;
}

.cart-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.cart-input button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f81f4;
  padding: 0 1rem;
  /* border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; */
  border-radius: 5px;
  height: 2.6rem;
  color: #fff;
}

.orders-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1.5rem;
}

.orders-grid .item {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: 8px;
  max-width: 20rem;
}

.orders-grid span {
  color: var(--text-2c);
}

.orders-grid div.bold {
  font-weight: 600;
  font-size: 1.1rem;
}

.orders-grid div.light {
  font-size: 0.8rem;
}

.orders-grid img.item-img {
  width: 100%;
  height: 15rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.o5 {
  opacity: 0.5;
}

.uncheck {
  display: block;
  border: 0.75px solid #797979;
  border-radius: 2px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.check {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color) !important;
}

.show_multi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 1rem;
  background-color: #fff;
  padding: 1rem 2rem;
  width: 80%;
  margin: auto;
  z-index: 100;
}

.show_multi span {
  color: #222222;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  margin-right: 1rem;
}

.show_multi .primary-btn {
  height: 3rem;
  margin-right: 1rem;
}

.table-wrapper-col {
  position: relative;
}

.cart-img .ant-result-icon > .anticon {
  font-size: 50px;
}

.cart-img .ant-result {
  padding: 32px 32px;
}
.product-card .ant-table-tbody > tr.ant-table-placeholder {
  height: 8rem;
}
