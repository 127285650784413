.accountHead {
    background: #F7F7F7;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 35px 25px;
    margin-bottom: 10px;
}

.account-budget {
    margin-top: -15px;
    margin-right: 10px;
}
.account-budget .balanceTitle {
    margin-bottom: 0px;
}

.account-budget .balanceText {
    margin-top: 0rem;
    font-size: 17px;
    color: #ED3E43;
    font-weight: 600;
}

.anticon-more {
    font-size: 28px;
}

.accountNote{
    color: #587293;
}

.viewAccount {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 9px;
    margin-top: 10px;
  }
   
  .viewAccount p {
    color: #adadad;
    margin-bottom: 2px;
    font-weight: 400;
    line-height: 28px;
  }
   
  .viewAccount span {
    color: #1e1e1e;
    font-weight: 600;
    line-height: 28px;
  }
  
  .viewAccount .notBold {
    color: #333333;
    font-weight: 500 !important;
  }

  .accountHead .moreDetails{
       cursor: pointer
  }

  .main_section {
    background: #bfbfbf3d;
    padding: 10px;
    border-radius: 10px;
  }
  .main_section .header_section span{
    color: #000000d9;
    font-weight: 600;
    font-size: 12px;
  }
  .main_section .amount_main_section{
    margin-top: 10px;
    display: flex;
    align-items: baseline;
    gap: 10px;
    
  }
  .main_section .amount_section{
    color: #000000d9;
    font-weight: 600;
    font-size: 20px;
  }
  .main_section .text_section{
    color: #00000080;
    font-weight: 600;
    font-size: 10px;
  }