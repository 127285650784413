.verify-section {
  gap: 1.5rem;
}

.verify-section .img-section img {
  width: 13rem;
  height: 13.5rem;
}

.verify-section .header-section {
  margin-bottom: 1.5rem;
}

.verify-section .header-section .header-text {
  font-size: 1.6rem;
  color: #122549;
  font-weight: 600;
  margin-bottom: 0rem;
}

.verify-section .header-section .sub-text {
  font-size: 1rem;
  font-weight: normal;
  color: #05152c;
  opacity: 0.5;
}

.email-active-wraper {
  gap: 1.5rem;
}

.email-active-wraper .icon img {
  width: 3rem;
  height: 3rem;
}

.email-active-wraper .arrow-section img {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.email-active-wraper .content-header {
  color: #122549;
  opacity: 1;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.email-active-wraper .content-sub-header {
  color: #05152c;
  opacity: 0.5;
  font-size: 0.9rem;
  font-weight: normal;
}

.email-active-wraper .active-section,
.email-active-wraper .email-section {
  background: #ffffff;
  box-shadow: 0px 3px 12px #e3e4e5;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem;
  min-height: 5.5rem;
}

.kyc-common-section {
  margin-top: 2rem;
}

.kyc-common-section .kyc-common-wraper {
  margin-bottom: 1rem;
}

.kyc-common-section .main-header {
  color: var(--text-21);
  font-size: 1.1rem;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.kyc-common-section .header-text {
  color: var(--text-21);
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.9;
}

.kyc-common-section .sub-header-text {
  color: #7c7d7e;
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0.7;
}

.kyc-notify .kyc-notify-container {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 7px;
  opacity: 1;
  padding: 3rem 3rem 4rem 2rem;
  margin-bottom: 2.5rem;
}

.kyc-notify-container .header {
  color: var(--text-21);
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.kyc-notify-container .text {
  color: #7c7d7e;
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0.7;
}

.kyc-notify-container button span {
  color: var(--primary-color);
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0.7;
}

.kyc-notify button img {
  width: 0.9rem;
  margin-right: 0.5rem;
}

.kyc-header-section .kyc-close img {
  width: 1.3rem;
  margin-left: 2.5rem;
  cursor: pointer;
}

.kyc-header-section .kyc-contact {
  color: var(--text-21);
}

.kyc-header-section .kyc-contact span {
  color: var(--primary-color);
  cursor: pointer;
}

.kyc-header-section .kyc-header .header-text {
  font-size: 1.6rem;
  color: var(--text-21);
}

.kyc-header-section .kyc-header .sub-header-text {
  color: var(--text-76);
}

/* pop up header */
.pop-up-section {
  margin: 3.5rem 0rem 2rem 1rem;
}

/* .pop-up-section .pop-up-container{
    width: 90%;
} */
/* .pop-up-section .header {
    margin-bottom: 1rem;
} */
.pop-up-section .header-text {
  font-size: 1.5rem;
  color: var(--text-21);
}

.pop-up-section .sub-header-text {
  color: var(--text-21);
  opacity: 0.7;
}

/* Do Don't css start */
.do-section .do-container {
  background: #f7f7f7;
  padding: 3.2rem 0rem 5.5rem 1rem;
}

.do-section .do-container {
  width: 90%;
}

.do-section .do-div li,
.do-section .donot-div li {
  list-style: none;
  color: var(--text-7C);
}

.do-section .do-div li span,
.do-section .donot-div li span {
  color: var(--text-21);
  font-size: 1rem;
  font-weight: 600;
}

.do-section .header-text {
  color: var(--text-21);
  font-size: 1.2rem;
}

/* upload document css start */
.upload-section {
  margin: 0rem 0rem 2rem 1rem;
}

.upload-section .upload-container {
  border: 1px dashed #cbcbcb;
  border-radius: 6px;
  height: 11.5rem;
}

.upload-section .upload-text {
  color: var(--text-21);
}

.upload-section .upload-card Button span {
  color: var(--primary-color) !important;
}

/* .verify-section .email-section .ant-steps-item-title {
    font-size: 12px !important;
    padding-right: 0px !important;
} */

.verify-section .email-section .ant-steps-dot .ant-steps-item-tail {
  margin: 0 0 0 10px !important;
  top: -6px !important;
}

.verify-section .email-section .ant-steps-dot .ant-steps-item-icon {
  width: 10px !important;
  height: 10px !important;
  margin-left: 0px !important;
}

.verify-section .email-section .ant-steps-dot .ant-steps-item-tail::after {
  margin-left: 5px !important;
}

.verify-section .email-section .ant-steps-dot .ant-steps-item-content {
  width: 130px !important;
}

.verify-section .email-section .ant-steps-item-title {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.verify-section
  .email-section
  .ant-steps-label-vertical
  .ant-steps-item-content {
  margin-top: 0px !important;
  text-align: start !important;
}

.verify-section .email-section .ant-steps-item-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
  margin: 0px 5px 0px 0px !important;
}

.verify-section .email-section .ant-steps-navigation .ant-steps-item::after {
  display: none !important;
}

.verify-section
  .email-section
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff !important;
}

.verify-section .email-section .ant-steps-error-icon svg {
  color: #fff !important;
}

.verify-section .email-section .ant-steps-item-icon .ant-steps-icon {
  top: -5.5px !important;
}

.verify-section .email-section .ant-steps-item-container {
  display: flex !important;
  align-items: center !important;
  padding-bottom: 20px !important;
}

.verify-section .email-section .ant-steps-item-finish .ant-steps-item-icon {
  background-color: green !important;
}

.verify-section .email-section .ant-steps-item-error .ant-steps-item-icon {
  background-color: #ff4d4f !important;
}

.verify-section
  .email-section
  .ant-steps-navigation
  .ant-steps-item.ant-steps-item-active::before {
  height: 0.3rem !important;
  border-radius: 5px !important;
}

.verify-section .email-section .ant-steps-navigation .ant-steps-item-container {
  margin-left: 0rem !important;
}

.verify-section .email-section .ant-steps-navigation {
  padding-top: 15px !important;
}

.verify-section .email-section .ant-steps-item::before {
  /* background-color: #ff4d4f !important; */
  display: none;
}
