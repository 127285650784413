.mediumDropdown {
  min-width: 200px;
}

.dimText {
  color: var(--text-b4);
  font-size: 11px;
  margin-bottom: 0px;
  padding: 10px 0px 0px 10px;
}

.dropDivider {
  margin-bottom: 3px !important;
  margin-top: 3px !important;
}

.dropNote {
  max-width: 220px;
  background: #f5f5f5;
  padding: 12px;
  font-size: 14px;
}

.overflowCol {
  height: 80vh;
  overflow: auto;
}

.userDragger .ant-upload {
  height: 17em !important;
}

.submitRow {
  border-top: 1px solid #00000029;
  width: 100%;
  height: 80px;
  margin-top: 30px;
  justify-content: flex-end;
}

.userContentWrap {
  padding: 2rem 3rem 0rem 2rem !important;
  margin-bottom: 50px !important;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview img {
  width: 14rem;
  height: 14rem;
}

.nameItem label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.nameItem {
  margin-bottom: 1rem;
}

.bulk-expense .nameItem {
  min-width: 15rem;
  max-width: 20rem;
}

.nameItem .ant-checkbox-wrapper {
  display: flex;
}

.nameItem .error {
  color: red;
}

.inline-block {
  display: inline-block !important;
}

.duplicate {
  position: relative;
}

.duplicate img {
  margin-left: 0.5rem;
}
.bulk-expense .ant-upload-drag {
  width: 40rem;
  height: 15rem;
}

.duplicate-hover {
  position: absolute;
  z-index: 1000;
  left: 0;
  padding: 1rem;
  background-color: #122549;
  width: 15rem;
  transform: scale(0);
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
}
.duplicate:hover .duplicate-hover {
  transform: scale(1);
  transition: all 300ms;
  left: 1.7rem;
  top: 2rem;
}
.resolve-now {
  color: #3f81f4;
}
.duplicate-hover::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #122549;
  left: 22px;
  top: -6px;
}
.bulk-expense .userDragger {
  display: flex;
}

.new-line {
  width: 15rem;
  padding: 10px 20px;
  margin-top: 20px;
}

.scan-item span:last-child {
  color: #262626;
  font-size: 1rem;
}

.scan-item span:first-child {
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #767676;
}

.scan-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.img-sec {
  display: grid;
  grid-template-columns: auto auto auto;
}
